<template>
  <div>
    <v-container class="indigo lighten-5 my-5">
      <v-card class="white pa-3">
        <h1 class="text-center subtitle-4 black--text">
          تعديل البروفايل
        </h1>
        <v-row class="mt-8"> </v-row>
        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="data.username"
              outlined
              label="اسم المستخدم"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="data.password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="الرمز"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="data.ensurePassword"
              outlined
              :type="isEnsurePasswordVisible ? 'text' : 'password'"
              label="تاكيد الرمز"
              placeholder="············"
              :append-icon="isEnsurePasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isEnsurePasswordVisible = !isEnsurePasswordVisible"
            ></v-text-field>

            <v-btn block color="primary" class="mt-6" @click="changeProfile" :loading="changeBtnLoading">
              تعديل
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
    <!--- Dailog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center">
          {{ dialogData.bodyText }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false">
            تم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  data () {
    return {
      data: {
        username: null,
        password: null,
        ensurePassword: null,
      },
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      isPasswordVisible: false,
      isEnsurePasswordVisible: false,
      changeBtnLoading: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },
    }
  },
  created () {
    this.getAccountUserName()
  },
  methods: {
    getAccountUserName () {
      this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
      this.axios
        .get('/profile')
        .then(Response => {
          if (Response.data.status === 401) {
            this.$store.dispatch('submitLogout')
          } else {
            this.data.username = Response.data.results.account_username
          }
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    changeProfile () {
      if (this.data.password !== this.data.ensurePassword) {
        this.showDialogfunction('تاكيد الرمز خاطئ', '#FF5252')
      } else {
        this.changeBtnLoading = true
        this.axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        this.axios
          .put('profile', {
            username: this.data.username,
            password: this.data.password,
          })
          .then(Response => {
            this.changeBtnLoading = false
            if (Response.data.error == false) {
              this.showDialogfunction(Response.data.results, 'primary')
            } else {
              this.showDialogfunction(Response.data.results, '#FF5252')
            }
          })
          .catch(error => {
            this.changeBtnLoading = false
            this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
            console.log('error', error)
          })
      }
    },

    showDialogfunction (bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },
  },
}
</script>
